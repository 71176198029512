import { forwardRef, ReactNode } from 'react';
import { VariantProps, cva } from 'class-variance-authority';

import { useKeyRelease } from '@virginexperiencedays/hooks';

import { IconCloseModal } from '../../icons/IconCloseModal';
import { cn } from '@virginexperiencedays/tailwind';

export type ModalProps = {
  children: ReactNode;
  isOpen: boolean;
  isContentNonScrollable?: boolean;
  mobilePin?: VariantProps<typeof mobilePinVariants>['mobilePin'];
  className?: string;
  outerClassName?: string;
  dataTestId?: string;
  hideModal: () => void;
  stickyClose?: boolean;
};

const mobilePinVariants = cva(
  'bg-background-page border border-solid border-border-neutral rounded-t-s mx-auto w-full overflow-y-auto md:my-20 md:rounded-lg max-w-3xl',
  {
    variants: {
      mobilePin: {
        center: 'm-4 max-h-[80%] rounded md:mx-auto md:my-20 md:max-h-[unset]',
        bottom: 'absolute bottom-0 max-h-[90%] md:static md:bottom-[unset] md:max-h-[unset]',

        full: 'md:min-h-auto min-h-full',
      },
    },
    defaultVariants: {
      mobilePin: 'center',
    },
  }
);

export const Modal = forwardRef<HTMLDivElement, ModalProps>(
  (
    {
      children,
      isOpen,
      isContentNonScrollable,
      hideModal,
      mobilePin = 'center',
      stickyClose = false,
      className,
      outerClassName,
      dataTestId = 'modal',
    },
    ref
  ) => {
    useKeyRelease('Escape', () => {
      isOpen && hideModal();
    });

    if (!isOpen) return null;

    return (
      <div
        className={cn(
          'fixed left-0 top-0 z-[500] h-full w-full backdrop-blur-sm',
          'after:absolute after:inset-0 after:bg-gray-800 after:opacity-40'
        )}
        onClick={hideModal}
        data-testid="modal-background"
      >
        <div
          ref={ref}
          className={cn(
            'absolute inset-0 z-[700] overflow-y-auto',
            mobilePin === 'center' && 'items-[initial] flex items-center md:block',
            mobilePin === 'full' && 'top-4 md:top-0',
            outerClassName
          )}
          data-testid="modal-placeholder"
        >
          <div
            className={cn(
              mobilePinVariants({ mobilePin }),
              isContentNonScrollable && 'overflow-y-hidden',
              className
            )}
            data-testid={dataTestId}
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className={cn(
                'relative flex-1',
                !isContentNonScrollable && 'overflow-y-auto overflow-x-hidden'
              )}
              data-testid="modal-content"
            >
              <button
                className={cn(
                  'text-neutral-strong right-0 top-0 z-[600] cursor-pointer rounded bg-transparent p-4 text-base leading-none',
                  stickyClose ? 'fixed' : 'absolute'
                )}
                onClick={hideModal}
                data-testid="modal-close-icon"
              >
                <IconCloseModal />
              </button>
              {children}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

Modal.displayName = 'Modal';
