import aa from 'search-insights';
import { createInsightsMiddleware } from 'instantsearch.js/es/middlewares';

import { v4 as uuidv4 } from 'uuid';

import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useInstantSearch } from 'react-instantsearch-core';

/*
 * Use this component if you want to enable Algolia tracking on the page where InstantSearch exists
 * This component will initialize `search-insights` client and attach it to InstantSearch instance via `addMiddlewares`
 */
export function InsightsMiddleware() {
  const { addMiddlewares } = useInstantSearch();

  useEffect(() => {
    /* we have to ensure that token is read first before attaching middleware to ensure no race conditions nor anonymous token are being sent */
    let uuid = localStorage.getItem('algoliaUserToken');
    if (!uuid) {
      uuid = uuidv4();
    }

    localStorage.setItem('algoliaUserToken', uuid);

    const middleware = createInsightsMiddleware({
      insightsClient: aa,
      insightsInitParams: {
        userToken: uuid,
      } as any,
    });

    return addMiddlewares(middleware);
  }, [addMiddlewares]);
  return null;
}

/*
 * This hook should be used only to READ user token from local storage or return undefined
 * It is necessary as we send custom events to algolia, where there is NO InstantSearch instance ie. AddToBasket
 * Normally when page uses InsightsMiddleware & InstantSearch instance it will initialize `search-insights` client for us and set the userToken aswell
 *
 * @returns { uuid: string | undefined }
 */
export const useAlgoliaUserToken = (): { uuid: string | undefined } => {
  const [uuid, setUuid] = useState<string>();
  useEffect(() => {
    const token = localStorage.getItem('algoliaUserToken');

    if (token) {
      setUuid(token);
    }
  }, []);

  return { uuid };
};

/*
 * This function should be used on pages where there is NO InstantSearch Instance in the scope
 * As algolia tracking requires `search-insights` to be initialised before sending events via `aa()` function
 */
export const initAlgoliaTrackingClient = (): void => {
  aa('init', {
    appId: process.env.NEXT_PUBLIC_PRODUCTS_ALGOLIA_APP_ID,
    apiKey: process.env.NEXT_PUBLIC_PRODUCTS_ALGOLIA_SEARCH_API_KEY,
  });
};

/*
 * Use this hook if you want to write or read query id to/from local storage
 * userToken and queryId are different concepts in Algolia Tracking
 * userToken is used to identify user, and queryId is used to connect multiple events to the user so we can recreate the user journey ie. for conversion events
 *
 * @returns { queryId: string | undefined, setQueryId: (query: string) => void, saveToLocalStorage: (query: string) => void }
 */
export const useAlgoliaQueryId = (): {
  queryId: string | undefined;
  saveToLocalStorage: (query: string) => void;
  setQueryId: Dispatch<SetStateAction<string>>;
} => {
  const [queryId, setQueryId] = useState<string>();
  // exposing function via hook for better visibilty of the naming convention
  function saveToLocalStorage(query: string) {
    localStorage.setItem('queryid', query);
  }

  useEffect(() => {
    const localQuery = localStorage.getItem('queryid');
    if (localQuery) {
      setQueryId(localQuery);
    }
  }, []);

  return { queryId, setQueryId, saveToLocalStorage };
};
