import {
  type HTMLAttributes,
  type ComponentProps,
  type ElementRef,
  type ComponentPropsWithoutRef,
  forwardRef,
} from 'react';
import { cn } from '@virginexperiencedays/tailwind';

import { Drawer as DrawerPrimitive } from 'vaul-no-scroll-restoration';

/**
 * Ref: https://ui.shadcn.com/docs/components/drawer
 */
export const Drawer = ({
  shouldScaleBackground = true,
  ...props
}: ComponentProps<typeof DrawerPrimitive.Root>) => (
  <DrawerPrimitive.Root shouldScaleBackground={shouldScaleBackground} {...props} />
);
Drawer.displayName = 'Drawer';

export const DrawerTrigger = DrawerPrimitive.Trigger;

export const DrawerPortal = DrawerPrimitive.Portal;

// Close Button
export const DrawerClose = forwardRef<
  ElementRef<typeof DrawerPrimitive.Close>,
  ComponentPropsWithoutRef<typeof DrawerPrimitive.Close>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Close
    ref={ref}
    className={cn(
      'focus:border-tones-info-500 cursor-pointer select-none text-center align-middle text-sm font-semibold leading-[14px] transition-colors focus:outline-0',
      className
    )}
    {...props}
  />
));
DrawerClose.displayName = DrawerPrimitive.Close.displayName;

// Overlay
export const DrawerOverlay = forwardRef<
  ElementRef<typeof DrawerPrimitive.Overlay>,
  ComponentPropsWithoutRef<typeof DrawerPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Overlay
    ref={ref}
    className={cn('fixed inset-0 z-50 bg-black/80', className)}
    {...props}
  />
));
DrawerOverlay.displayName = DrawerPrimitive.Overlay.displayName;

// Drawer content
export const DrawerContent = forwardRef<
  ElementRef<typeof DrawerPrimitive.Content>,
  ComponentPropsWithoutRef<typeof DrawerPrimitive.Content> & { hasHandle?: boolean }
>(({ className, children, hasHandle, ...props }, ref) => (
  <DrawerPortal>
    <DrawerOverlay />
    <DrawerPrimitive.Content
      ref={ref}
      className={cn(
        'bg-background-page border-border-neutral fixed inset-x-0 bottom-0 z-50 mt-24 flex h-auto flex-col rounded-t-[10px] border border-solid',
        className
      )}
      {...props}
    >
      {hasHandle && <div className="bg-border-neutral mx-auto mt-4 h-1 w-10 rounded-full" />}
      {children}
    </DrawerPrimitive.Content>
  </DrawerPortal>
));
DrawerContent.displayName = 'DrawerContent';

export const DrawerHeader = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('grid gap-2 p-4', className)} {...props} />
);
DrawerHeader.displayName = 'DrawerHeader';

export const DrawerTitle = forwardRef<
  ElementRef<typeof DrawerPrimitive.Title>,
  ComponentPropsWithoutRef<typeof DrawerPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Title
    ref={ref}
    className={cn('font-inter text-2xl font-semibold leading-none tracking-tight', className)}
    {...props}
  />
));
DrawerTitle.displayName = DrawerPrimitive.Title.displayName;

export const DrawerDescription = forwardRef<
  ElementRef<typeof DrawerPrimitive.Description>,
  ComponentPropsWithoutRef<typeof DrawerPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DrawerPrimitive.Description
    ref={ref}
    className={cn('text-neutral text-base leading-none', className)}
    {...props}
  />
));
DrawerDescription.displayName = DrawerPrimitive.Description.displayName;

export const DrawerBody = ({ className, children, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('flex flex-col gap-2 px-4 leading-4', className)} {...props}>
    {children}
  </div>
);
DrawerBody.displayName = 'DrawerBody';

export const DrawerFooter = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('mt-auto flex flex-col gap-2 p-4 sm:flex-row', className)} {...props} />
);
DrawerFooter.displayName = 'DrawerFooter';
